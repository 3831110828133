import React, { useEffect, useState, useRef } from 'react';
import { JSONEditor } from '@json-editor/json-editor/dist/jsoneditor.js';
import 'bootstrap/scss/bootstrap.scss'


const JsonEditorComponent = ({ initialValue, onChange }) => {
  const editorRef = useRef(null);
  const [loadedData, setLoadedData] = useState(null);

  useEffect(() => {
    editorRef.current.innerHTML = '';

    const editor = new JSONEditor(editorRef.current, {
      schema: initialValue,
      ajax: true,
      disable_properties: false,
      disable_collapse: true,
      startval: loadedData,
      theme: 'bootstrap5'
    });

    const handleChange = () => {
      const updatedJson = editor.getEditor('root');
      onChange(updatedJson.value);
    };

    editor.on('change', handleChange);

    return () => {
      editor.off('change', handleChange);
      editor.destroy();
      editorRef.current.innerHTML = '';
    };

  }, [loadedData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const fileContent = JSON.parse(e.target.result);
        setLoadedData(fileContent);
      } catch (error) {
        console.error('Error parsing JSON file:', error);
      }
    };

    reader.readAsText(file);
  };

  return <div>
    <input type="file" onChange={handleFileChange} />
    <div ref={editorRef}></div>
  </div>;
};

export default JsonEditorComponent;
