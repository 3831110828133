import React, { useState } from 'react';
import JsonEditorComponent from './components/JsonEditorComponent';
import SaveToFile from './components/SaveToFile';

import schema from './js/schema.config'

const App = () => {
  const [jsonData, setJsonData] = useState('');

  const handleJsonChange = (updatedJson) => {
    setJsonData(updatedJson)
  };

  return (
    <div>
      <h2>Openlayers Map Config Editor</h2>
      <JsonEditorComponent initialValue={schema} onChange={handleJsonChange} />
      <SaveToFile data={jsonData} fileName="config.json"/>
    </div>
  );
};
export default App;

// <pre>{JSON.stringify(jsonData, null, 2)}</pre>