import React from 'react';
import { saveAs } from 'file-saver';

const SaveToFile = ({ data, fileName }) => {
  const handleSaveToFile = () => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    saveAs(blob, fileName);
  };

  return (
    <div>
      <button onClick={handleSaveToFile}>Save to File</button>
    </div>
  );
};

export default SaveToFile;
