let schema = {
    "title": "map config",
    "type": "object",
    "required": [
        "view",
        "layers",
        "modules"
    ],
    "properties": {
        "view": {
            "type": "object",
            "title": "View",
            "description": "A View object represents a simple 2D view of the map. This is the object to act upon to change the center, resolution, and rotation of the map.",
            "required": [
                "center",
                "zoom"
            ],
            "properties": {
                "center": {
                    "type": "array",
                    "title": "Center",
                    "description": "The initial center for the view.",
                    "items": [
                        {
                            "type": "number",
                            "title": "Longitude"
                        },
                        {
                            "type": "number",
                            "title": "Latitude"
                        }
                    ],
                    "minItems": 2,
                    "maxItems": 2,
                    "default": [22.942377, 40.745845]
                },
                "zoom": {
                    "type": "number",
                    "title": "Zoom",
                    "description": "Zoom level used to calculate the initial resolution for the view.",
                    "default": 14,
                    "format": "number",
                    "minimum": 1,
                    "maximum": 28
                },
                "maxZoom": {
                    "type": "number",
                    "title": "maxZoom",
                    "description": "The maximum zoom level used to determine the resolution constraint.",
                    "default": 28,
                    "format": "number",
                    "minimum": 1,
                    "maximum": 28
                },
                "minZoom": {
                    "type": "number",
                    "title": "minZoom",
                    "description": "The minimum zoom level used to determine the resolution constraint.",
                    "default": 1,
                    "format": "number",
                    "minimum": 1,
                    "maximum": 28
                },
                "projection": {
                    "type": "string",
                    "title": "Projection",
                    "description": "The projection. The default is Spherical Mercator.",
                    "default": "EPSG:3857",
                    "enum": ["EPSG:3857", "EPSG:4326", "EPSG:900913", "EPSG:2100"]
                },
                "extent": {
                    "type": "array",
                    "title": "Extent",
                    "description": "The extent that constrains the view, in other words, nothing outside of this extent can be visible on the map.",
                    "items": [
                        {
                            "type": "number",
                            "title": "minX"
                        },
                        {
                            "type": "number",
                            "title": "minY"
                        },
                        {
                            "type": "number",
                            "title": "maxX"
                        },
                        {
                            "type": "number",
                            "title": "maxY"
                        }
                    ],
                    "minItems": 4,
                    "maxItems": 4,
                    "default": [22.942377, 40.745845, 23.942377, 43.745845]
                },
                "rotation": {
                    "type": "number",
                    "title": "Rotation",
                    "description": "The initial rotation for the view in radians (positive rotation clockwise, 0 means North).",
                    "default": 0,
                    "format": "number"
                }
            }
        },
        "layers": {
            "type": "array",
            "title": "Layers",
            "uniqueItems": true,
            "items": {
                "type": "object",
                "title": "Layer",
                "required": [
                    "title",
                    "source",
                    "fields"
                ],
                "properties": {
                    "title": {
                        "type": "string",
                        "title": "Title",
                    },
                    "opacity": {
                        "type": "number",
                        "title": "Opacity",
                        "description": "Opacity from 0.0 to 1.0).",
                        "default": 1.0,
                        "minimum": 0.0,
                        "maximum": 1.0,
                        "multipleOf": 0.01
                    },
                    visible: {
                        "type": 'boolean',
                        "title": 'Visibility',
                        "default": true,
                        "enum": [true, false]
                    },
                    "extent": {
                        "type": "array",
                        "title": "Extent",
                        "description": "The bounding extent for layer rendering. The layer will not be rendered outside of this extent.",
                        "items": [
                            {
                                "type": "number",
                                "title": "minX"
                            },
                            {
                                "type": "number",
                                "title": "minY"
                            },
                            {
                                "type": "number",
                                "title": "maxX"
                            },
                            {
                                "type": "number",
                                "title": "maxY"
                            }
                        ],
                        "minItems": 4,
                        "maxItems": 4,
                        "default": [-180.0, -90.0, 180.0, 90.0]
                    },
                    "minZoom": {
                        "type": "number",
                        "title": "minZoom",
                        "description": "The minimum view zoom level (exclusive) above which this layer will be visible.",
                        "format": "number",
                        "default": 1,
                        "minimum": 1,
                        "maximum": 28
                    },
                    "maxZoom": {
                        "type": "number",
                        "title": "maxZoom",
                        "description": "The maximum view zoom level (inclusive) at which this layer will be visible.",
                        "format": "number",
                        "default": 28,
                        "minimum": 1,
                        "maximum": 28
                    },
                    "source": {
                        "type": "object",
                        "title": "Source",
                        "description": "Source for this layer.",
                        "properties": {
                            "url": {
                                "type": "string",
                                "format": "url",
                                "description": "WMS service URL."
                            },
                            "params": {
                                "type": "object",
                                "title": "params",
                                "description": "WMS request parameters.",
                                "required": [
                                    "LAYERS",
                                    "VERSION"
                                ],
                                "properties": {
                                    "LAYERS": {
                                        "type": "string"
                                    },
                                    "STYLES": {
                                        "type": "string"
                                    },
                                    "VERSION": {
                                        "type": "string",
                                        "default": "1.3.0"
                                    }
                                }
                            },
                            "serverType": {
                                "type": "string",
                                "description": "The type of the remote WMS server: mapserver, geoserver, carmentaserver, or qgis.",
                                "default": "geoserver",
                                "enum": ["mapserver", "geoserver", "carmentaserver", "qgis"]
                            }
                        }
                    },
                    "fields": {
                        "type": "array",
                        "title": "Fields",
                        "uniqueItems": true,
                        "items": {
                            "type": "object",
                            "title": "Field",
                            "required": [
                                "property",
                                "el",
                                "en"
                            ],
                            "properties": {
                                "property": {
                                    "type": "string",
                                    "description": "Property name"
                                },
                                "el": {
                                    "type": "string",
                                    "description": "Translate in greek"
                                },
                                "en": {
                                    "type": "string",
                                    "description": "Translate in english"
                                }
                            }
                        }
                    }
                }
            }
        },
        "modules": {
            "type": 'array',
            "title": 'Map Modules',
            "items": {
                "type": 'string',
                "enum": ['Module 1', 'Module 2', 'Module 3', 'Module 4', 'Module 5', 'Module 6']
            },
            "uniqueItems": true
        }
    }
}

export default schema
